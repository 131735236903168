import dynamic from "next/dynamic";
import Head from "next/head";
import React from "react";
import { CONTRACT_ADDRESS, OS_SLUG } from "../constants/env";

const MintBox = dynamic(() => import("../components/MintBox"), { ssr: false });

export default function Home() {
  return (
    <>
      <Head>
        <title>KakiDaigaku</title>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="NFT, Free, Ethereum" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="min-h-screen w-full grid grid-cols-1 grid-rows-1 place-items-center">
        <div className="bkg w-full relative grid grid-cols-1 grid-rows-1 row-start-1 col-start-1 select-none -z-10">
          <img
            className="h-full w-full row-start-1 col-start-1 object-contain -z-10"
            src="/background@2x.webp"
            srcSet="/background@2x.webp 2x, /background.webp 1x"
            alt=""
          />
        </div>

        <div className="w-1/2 col-start-1 row-start-1">
          <MintBox />
        </div>
      </div>
    </>
  );
}
